import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useScreenWidth from '../../../../hooks/useScreenWidth';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Image from '../../Image';
import { updateCart } from '../../../../redux/actions/cart';
import { decryptPartId, limpiarNombre, procesarReferencia } from '../../../../utils';
import Spinner from '../../Spinner';
import { TbTrashXFilled } from 'react-icons/tb';

const EachCardDetalle = ({ pieza, isCasco = false, className }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const screenWidth = useScreenWidth();
    const cart = useSelector(state => state.cart);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleRemoveToCart = async (e, pieza) => {
        e.stopPropagation();
        if (isLoading) return;

        setIsLoading(pieza?.pieza_integracion_id);
        const piezas = cart.piezas.filter(part => part.pieza_integracion_id !== pieza.pieza_integracion_id);
        if (piezas.length > 0) {
            dispatch(updateCart({
                ...cart,
                piezas
            }));
        } else {
            dispatch(updateCart({ ...cart, total: 0, total_iva: 0, piezas }));
        }
        removeToLocalStorage(pieza.pieza_integracion_id);
        setIsLoading(false);
    };

    const removeToLocalStorage = (IdToRemove) => {
        const cartLocalStorage = JSON.parse(localStorage.getItem('cart'));
        const newCart = cartLocalStorage?.filter(part => {
            const partId = JSON.parse(decryptPartId(part));
            return partId.id !== IdToRemove;
        });
        if (newCart.length === 0) return localStorage.removeItem('cart');
        return localStorage.setItem('cart', JSON.stringify(newCart));
    };

    const navigateToDetail = () => {
        const jsonPart = JSON.parse(pieza.integracion_json);
        navigate(
            `/detalle/${limpiarNombre(jsonPart?.marca)}/${limpiarNombre(
                jsonPart?.modelo
            )}/${limpiarNombre(jsonPart?.familia)}/${limpiarNombre(
                jsonPart?.nombre
            )}/${procesarReferencia(jsonPart.referencia)}/${jsonPart?.id}`
        );
    };

    return (
        <>
            <div
                onClick={() => {
                    if (pieza.estado !== 0 && pieza.estado !== 2) {
                        navigateToDetail();
                    }
                }}
                className={`detalle-carrito__pieza ${className} ${(pieza?.estado !== 1 && pieza?.estado !== 3) ? 'blocked' : ''}`}>
                <div className='detalle-carrito__pieza--foto'>
                    <span>
                        <Image foto={(pieza?.imgs === 'object' && pieza?.imgs?.length >= 0) ? '' : (pieza?.imgs?.split(',')[0] || '')} />
                    </span>
                </div>
                <div className='detalle-carrito__pieza--info'>
                    <h2>{pieza?.nombre}</h2>
                    <p className='detalle-carrito__pieza--info--subtitle'>{pieza?.marca?.nombre} {pieza?.modelo?.nombre}</p>
                    {screenWidth > 992 &&
                <>
                    <p className='detalle-carrito__pieza--info--labels'>
                        <span>OEM: <b>{pieza?.referencia_pieza}</b></span>
                        <span>ID: <b>{pieza?.pieza_integracion_id}</b></span>
                    </p>
                    <p className='detalle-carrito__pieza--info--observaciones'>{t('catalogo-piezas.pieza-card.observaciones')} {pieza?.notapublica || pieza?.observaciones || '-'}</p>
                </>
                    }
                </div>
                <h3 className='detalle-carrito__pieza--precio'>{(pieza?.estado !== 1 && pieza?.estado !== 3) && <span>{t('area-personal.favoritos.no-stock')}</span>} {(pieza?.precio_venta).toFixed(2)} €</h3>
                <span className={`detalle-carrito__pieza--eliminar ${isLoading ? 'disabled-to-delete' : ''}`} onClick={(e) => handleRemoveToCart(e, pieza)}>{isLoading === pieza?.id ? <Spinner className='spinner-eliminar-pieza'/> : <TbTrashXFilled />}</span>
            </div>
            {isCasco && <div
                onClick={navigateToDetail}
                className={`detalle-carrito__pieza ${className}`}>
                <div className='detalle-carrito__pieza--foto'>
                    <span>
                        <Image foto={(pieza?.imgs === 'object' && pieza?.imgs?.length >= 0) ? '' : (pieza?.imgs?.split(',')[0] || '')} />
                    </span>
                </div>
                <div className='detalle-carrito__pieza--info'>
                    <h4>{isCasco && 'CASCO '}{pieza?.nombre}</h4>
                    <p className='detalle-carrito__pieza--info--subtitle'>{pieza?.marca?.nombre} {pieza?.modelo?.nombre}</p>
                    {screenWidth > 992 &&
                    <p className='detalle-carrito__pieza--info--labels'>
                        <span>OEM: <b>{pieza?.referencia_pieza}</b></span>
                        <span>ID: <b>{pieza?.pieza_integracion_id}</b></span>
                    </p>
                    }
                </div>
                <h3 className='detalle-carrito__pieza--precio'>{(pieza?.estado !== 1 && pieza?.estado !== 3) && <span>{t('area-personal.favoritos.no-stock')}</span>} {(pieza?.casco || 0).toFixed(2)} €</h3>
            </div>}
        </>
    );
};

EachCardDetalle.propTypes = {
    pieza: PropTypes.object.isRequired,
    isCasco: PropTypes.bool,
    className: PropTypes.string
};

export default EachCardDetalle;
