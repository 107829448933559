import { t } from 'i18next';
import CryptoJS from 'crypto-js';
import config from '../config';

export const handleErrorMessage = (error) => {
    let errorMessage = t(`errors.${error}`);
    if (errorMessage.includes('errors')) errorMessage = t('errors.1');
    return errorMessage;
};

export const encryptPartId = (partId) => {
    const dataToString = JSON.stringify({
        id: partId,
        timestamp: Date.now()
    });
    const newPart = CryptoJS.AES.encrypt(dataToString, config.crypto.secretKey).toString();
    return newPart;
};

export const decryptPartId = (encryptedId) => {
    const bytes = CryptoJS.AES.decrypt(encryptedId, config.crypto.secretKey);
    const partId = bytes.toString(CryptoJS.enc.Utf8);
    return partId;
};

// VALIDATIONS FORMS
export const validarNIF = (identificacion) => {
    const regexNIF = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/;
    const regexNIE = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/;
    const regexCIF = /^[ABCDEFGHJNPQRSUVW][0-9]{7}[0-9A-J]$/;
    const regexNIPC = /^[125689]{1}\d{8}$/;
    const regexCIFInternacional = /^ES[ABCDEFGHJNPQRSUVW][0-9]{7}[0-9A-J]$/;
    const regexNIFCIFPortugal = /^[A-Z]{1,2}\d{9}$/;

    // Validar NIF o NIE en España
    if (regexNIF.test(identificacion) || regexNIE.test(identificacion)) {
        return true;
    }

    // Validar CIF en España
    if (regexCIF.test(identificacion) || regexCIFInternacional.test(identificacion?.toUpperCase())) {
        return true;
    }

    // Validar NIPC en Portugal
    if (regexNIPC.test(identificacion)) {
        return true;
    }

    if (regexNIFCIFPortugal.test(identificacion)) {
        return true;
    }

    // Si no coincide con ninguno de los patrones, retornar falso
    return false;
};

export const handleValidPhone = (e) => {
    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
        e.preventDefault();
    }
    if (e.target.value.length >= 15 && e.key !== 'Backspace' && e.key !== 'Tab') {
        e.preventDefault();
    }
};

export const handleValidCp = (e) => {
    const { key, target } = e;

    if (!/[0-9\s-]/.test(key) && key !== 'Backspace' && key !== 'Tab') {
        e.preventDefault();
    }

    if (target.value.length >= 8 && key !== 'Backspace' && key !== 'Tab') {
        e.preventDefault();
    }
};

export const validatePostalCode = (code, country) => {
    let isValid;
    if (country === 'PT') {
        isValid = (/^\d{4}[\s-]?\d{3}$/).test(code) || (/^\d{4}$/).test(code);
    }
    if (country === 'ES') {
        isValid = (/^\d{5}$/).test(code);
    }
    return isValid;
};

export const limpiarNombre = (nombre) => {
    // Mapa de reemplazo de caracteres acentuados
    if (!nombre) return null;
    const mapaAcentos = {
        á: 'a',
        é: 'e',
        í: 'i',
        ó: 'o',
        ú: 'u',
        ä: 'a',
        ë: 'e',
        ï: 'i',
        ö: 'o',
        ü: 'u',
        à: 'a',
        è: 'e',
        ì: 'i',
        ò: 'o',
        ù: 'u',
        Á: 'a',
        É: 'e',
        Í: 'i',
        Ó: 'o',
        Ú: 'u',
        Ä: 'a',
        Ë: 'e',
        Ï: 'i',
        Ö: 'o',
        Ü: 'u',
        À: 'a',
        È: 'e',
        Ì: 'i',
        Ò: 'o',
        Ù: 'u'
    };

    // Reemplazar los caracteres acentuados y comillas
    nombre = nombre?.replace(
        /[áéíóúäëïöüàèìòùÁÉÍÓÚÄËÏÖÜÀÈÌÒÙ]/g,
        (match) => mapaAcentos[match]
    );
    nombre = nombre?.replace(/[´'+]/g, ''); // Quitar comillas y el símbolo +

    // Convertir a minúsculas y reemplazar espacios o barras por guiones
    return nombre?.toLowerCase().replace(/[\s/]+/g, '-');
};

export const procesarReferencia = (referencia) => {
    if (typeof referencia !== 'string') return '';

    const referenciaProcesada = referencia?.replaceAll('-', '_').replaceAll(' ', '-');
    return referenciaProcesada;
};

export const updatePropertyMetaTag = (property, content) => {
    let metaTag = document.querySelector(
        `meta[property="${property}"]`
    );
    if (metaTag) {
        metaTag.content = content;
    } else {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('property', property);
        metaTag.setAttribute('content', content);
        document.head.appendChild(metaTag);
    }
};
