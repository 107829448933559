import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';
import FiltrosSection from './FiltrosSection';
import WhatsAppSection from '../../shared/WhatsAppSection';
import filter from '../../../images/catalogo-piezas/filter.png';

import './catalogoPiezas.scss';
import { useEffect, useRef, useState } from 'react';
import PiezaCard from './PiezaCard';
import useScreenWidth from '../../../hooks/useScreenWidth';

import Pagination from '../../shared/Pagination';
import SkeletonPiezaCard from './SkeletonPiezaCard';
import { toast } from 'react-toastify';
import NoDataCard from './NoDataCard';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilters } from '../../../redux/actions/filters';
import { apiRequest } from '../../../services/api';
import { useParams } from 'react-router-dom';
import { limpiarNombre } from '../../../utils';

const PageSize = 10;

const CatalogoPiezas = () => {
    const { t } = useTranslation();
    const screenWidth = useScreenWidth();
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.filters);
    const [isLoading, setIsLoading] = useState(true);
    const { marca } = useParams();

    const [isPrevPagePartDetail, setIsPrevPagePartDetail] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filterValues, setFilterValues] = useState({
        marca: { id: '', nombre: '' },
        modelo: { id: '', nombre: '' },
        familia: { id: '', nombre: '' },
        articulo: { id: '', nombre: '' },
        precioMin: '',
        precioMax: ''
    });
    const [order, setOrder] = useState('asc');

    const [searchParams, setSearchParams] = useState('');

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleQuery = (isReset, orderValue) => {
        let query = {
            ...filterValues,
            ref: searchParams,
            limit: PageSize,
            page: currentPage,
            order
        };
        if (isReset) {
            if (isReset === 'resetFilters') {
                query = {
                    ...query,
                    marca: { id: '', nombre: '' },
                    modelo: { id: '', nombre: '' },
                    familia: { id: '', nombre: '' },
                    articulo: { id: '', nombre: '' },
                    precioMin: '',
                    precioMax: ''
                };
            } else if (isReset === 'resetSearch') {
                query = { ...query, ref: '' };
            }
        }
        if (orderValue) {
            query = { ...query, order: orderValue };
        }
        return query;
    };

    const submitOrderValues = (e) => {
        setOrder(e.target.value);
        // en el caso que el total piezas sea menor o igual al total de resultados, ordenamos manualmente y no hacemos petición
        if (totalPiecesData <= PageSize) {
            let orderedParts;
            if (e.target.value === 'asc') {
                orderedParts = piecesData.sort(
                    (a, b) => a.precio_venta - b.precio_venta
                );
            } else {
                orderedParts = piecesData.sort(
                    (a, b) => b.precio_venta - a.precio_venta
                );
            }
            return setPiecesData(orderedParts);
        }
        const query = handleQuery(false, e.target.value);
        setCurrentPage(1);
        setPiecesData([]);
        handleRequestStock(query);
    };

    const submitFilterValues = (isReset) => {
        const query = handleQuery(isReset);
        handleSearch(query);
        screenWidth < 768 && setIsModalOpen(false);
    };

    const submitSearch = (isReset) => {
        const query = handleQuery(isReset);
        handleSearch(query);
    };

    const handleSearch = (query) => {
        !hasMoreData && setHasMoreData(true);
        if (currentPage === 1) {
            handleRequestStock(query);
        } else {
            setCurrentPage(1);
        }
        if (screenWidth < 992) {
            setPiecesData([]);
            setIsLoading(true);
        }
    };

    const submitUrlSearch = (brand) => {
        const query = {
            ...filterValues,
            marca: brand,
            limit: PageSize,
            page: currentPage,
            order
        };
        handleRequestStock(query);
    };

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPiecesData, setTotalPiecesData] = useState(0);
    const [piecesData, setPiecesData] = useState([]);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [isError, setIsError] = useState(false);

    const observerTarget = useRef(null);
    const handleRequestStock = async (filterValues) => {
        setIsLoading(true);
        const res = await apiRequest.stock.stockRequest(filterValues);
        if (res?.success) {
            isError && setIsError(false);
            screenWidth < 992 &&
                res?.total_items <= PageSize * currentPage &&
                setHasMoreData(false);
            screenWidth >= 992
                ? setPiecesData(res?.parts)
                : currentPage === 1
                    ? setPiecesData(res?.parts)
                    : setPiecesData((prev) => [...prev, ...res?.parts]);
            setTotalPiecesData(res?.total_items);
        } else {
            screenWidth < 992 && setIsError(true);
            toast.error(t('errors.1'));
        }
        return setIsLoading(false);
    };

    // dejar la página igual como estaba antes de ir a la página de pieza detalle
    const [piezaIdToScroll, setPiezaIdToScroll] = useState(null);
    const handleSavedFilters = () => {
        setIsPrevPagePartDetail(true);
        setFilterValues({ ...filters.filterValues });
        setSearchParams(filters.searchParams);
        setOrder(filters.order);
        setCurrentPage(filters.currentPage);
        setPiezaIdToScroll(filters.piezaId);
        if (screenWidth <= 992) {
            handleRequestStock({
                ...filters.filterValues,
                limit: PageSize * filters.currentPage,
                page: 1,
                order: filters.order,
                ref: filters.searchParams
            });
        } else {
            handleRequestStock({
                ...filters.filterValues,
                limit: PageSize,
                page: filters.currentPage,
                order: filters.order,
                ref: filters.searchParams
            });
        }
        dispatch(resetFilters());
    };

    useEffect(() => {
        if (marca && !filterValues?.marca?.nombre) {
            return;
        }
        if (filters) {
            handleSavedFilters();
        } else {
            !isPrevPagePartDetail &&
                hasMoreData &&
                handleRequestStock({
                    ...filterValues,
                    limit: PageSize,
                    page: currentPage,
                    order,
                    ref: searchParams
                });
        }
    }, [currentPage]);

    useEffect(() => {
        if (screenWidth < 992) {
            const observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        setCurrentPage((prev) => prev + 1);
                    }
                },
                { threshold: 1 }
            );
            if (observerTarget.current) {
                observer.observe(observerTarget.current);
            }
            return () => {
                if (observerTarget.current) {
                    observer.unobserve(observerTarget.current);
                }
            };
        }
    }, [observerTarget, screenWidth]);

    useEffect(() => {
        if (marca && filterValues.marca.id &&
            marca !== limpiarNombre(filterValues.marca.nombre)) {
            window.history.pushState({}, '', `/parts/${limpiarNombre(filterValues.marca.nombre)}`);
        }
    }, [filterValues.marca.id]);

    return (
        <div className="catalogo-piezas">
            <h1>{t('catalogo-piezas.title')}</h1>
            <h2>{totalPiecesData + ' ' + t('catalogo-piezas.subtitle')}</h2>
            <div className="catalogo-piezas__header">
                <div className="catalogo-piezas__header--search">
                    <input
                        type="text"
                        placeholder={t('catalogo-piezas.search')}
                        className="catalogo-piezas__header--search--input"
                        value={searchParams}
                        onChange={(e) => setSearchParams(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && submitSearch()}
                    />
                    <span
                        className={`catalogo-piezas__header--search--delete ${searchParams ? 'catalogo-piezas__header--search--delete--show' : ''}`}
                        onClick={() => {
                            setSearchParams('');
                            submitSearch('resetSearch');
                        }}
                    >
                        x
                    </span>
                    <AiOutlineSearch
                        onClick={() => submitSearch()}
                        className="catalogo-piezas__header--search--icon"
                    />
                </div>
                <span className="catalogo-piezas__header--filtrosbtn">
                    {t('catalogo-piezas.filtros')}
                    <span onClick={() => setIsModalOpen(true)}>
                        <img
                            src={filter}
                            alt="filter"
                            className="filtros-img"
                        />
                    </span>
                </span>
                <select
                    className="catalogo-piezas__header--order"
                    name="order"
                    id='order'
                    onChange={submitOrderValues}
                    defaultValue="asc"
                >
                    <option value="desc">{t('catalogo-piezas.desc')}</option>
                    <option value="asc">{t('catalogo-piezas.asc')}</option>
                </select>
            </div>
            <div className="catalogo-piezas__main">
                <FiltrosSection
                    isPrevPagePartDetail={isPrevPagePartDetail}
                    isModalOpen={isModalOpen}
                    handleCloseModal={handleCloseModal}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    submitFilterValues={submitFilterValues}
                    submitUrlSearch={submitUrlSearch}
                />
                <div className="catalogo-piezas__main--piezas">
                    {screenWidth < 992
                        ? piecesData?.map((pieza, idx) => (
                            <PiezaCard
                                key={pieza?.id}
                                pieza={pieza}
                                filters={{
                                    filterValues,
                                    searchParams,
                                    order,
                                    currentPage,
                                    piezaId: pieza?.id
                                }}
                                piezaIdToScroll={piezaIdToScroll}
                                setPiezaIdToScroll={setPiezaIdToScroll}
                                setIsPrevPagePartDetail={
                                    setIsPrevPagePartDetail
                                }
                            />
                        ))
                        : !isLoading &&
                          piecesData?.map((pieza, idx) => (
                              <PiezaCard
                                  key={pieza?.id}
                                  pieza={pieza}
                                  filters={{
                                      filterValues,
                                      searchParams,
                                      order,
                                      currentPage,
                                      piezaId: pieza?.id
                                  }}
                                  piezaIdToScroll={piezaIdToScroll}
                                  setPiezaIdToScroll={setPiezaIdToScroll}
                                  setIsPrevPagePartDetail={
                                      setIsPrevPagePartDetail
                                  }
                              />
                          ))}
                    {!isLoading && piecesData?.length === 0 && (
                        <div className="catalogo-piezas__main--piezas--no-data">
                            <NoDataCard
                                filterValues={filterValues}
                                searchParams={searchParams}
                            />
                        </div>
                    )}
                    {isLoading && (
                        <>
                            <SkeletonPiezaCard />
                            <SkeletonPiezaCard />
                            <SkeletonPiezaCard />
                            <SkeletonPiezaCard />
                            {screenWidth < 992 && (
                                <>
                                    <SkeletonPiezaCard />
                                    <SkeletonPiezaCard />
                                    <SkeletonPiezaCard />
                                </>
                            )}
                        </>
                    )}
                    {screenWidth < 992 && !isError && (
                        <div ref={observerTarget}></div>
                    )}
                </div>
            </div>
            {screenWidth >= 992 && piecesData?.length > 0 && (
                <Pagination
                    filters={filters}
                    isPrevPagePartDetail={isPrevPagePartDetail}
                    currentPage={currentPage}
                    totalCount={totalPiecesData}
                    pageSize={PageSize}
                    onPageChange={setCurrentPage}
                />
            )}
            {!isModalOpen && <WhatsAppSection />}
        </div>
    );
};

export default CatalogoPiezas;
