import { createBrowserRouter } from 'react-router-dom';
import MainLayout from '../views/MainLayout';
import CatalogoPiezas from '../views/MainLayout/CatalogoPiezas';
import CondicionesCompra from '../views/MainLayout/CondicionesCompra';
import Contacto from '../views/MainLayout/Contacto';
import PiezaDetalle from '../views/MainLayout/PiezaDetalle';
import AreaPersonal from '../views/MainLayout/AreaPersonal';
import MisDatos from '../views/MainLayout/AreaPersonal/MisDatos';
import MisDirecciones from '../views/MainLayout/AreaPersonal/MisDirecciones';
import PedidosRealizados from '../views/MainLayout/AreaPersonal/PedidosRealizados';
import ResumenCarrito from '../views/MainLayout/ResumenCarrito';
import Favoritos from '../views/MainLayout/AreaPersonal/Favoritos';
import PrivateRoutes from './PrivateRoutes';
import ResetPassword from '../views/ResetPassword';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: <CatalogoPiezas />
            },
            {
                path: '/parts/:marca',
                element: <CatalogoPiezas />
            },
            {
                path: '/detalle/:marca/:modelo/:familia/:articulo/:ref/:piezaId',
                element: <PiezaDetalle />
            },
            {
                path: '/compra',
                element: <CondicionesCompra />
            },
            {
                path: '/contacto',
                element: <Contacto />
            },
            {
                path: '/carrito',
                element: <ResumenCarrito />
            },
            {
                path: '/area-personal',
                element: <PrivateRoutes />,
                children: [
                    {
                        path: '/area-personal',
                        element: <AreaPersonal />,
                        children: [
                            {
                                path: '/area-personal/datos',
                                element: <MisDatos />
                            },
                            {
                                path: '/area-personal/direcciones',
                                element: <MisDirecciones />
                            },
                            {
                                path: '/area-personal/pedidos',
                                element: <PedidosRealizados />
                            },
                            {
                                path: '/area-personal/favoritos',
                                element: <Favoritos />
                            }
                        ]
                    }
                ]
            },
            {
                path: '*',
                element: <CatalogoPiezas />
            }
        ]
    },
    {
        path: '/reset-password/:token',
        element: <ResetPassword />
    }
]);
