import { GrPrevious, GrNext } from 'react-icons/gr';
import PropTypes from 'prop-types';
import imgDefault from '../../../../../images/placeholder.png';

import './deskCarousel.scss';
import Image from '../../../../shared/Image';
import { useState } from 'react';
import ModalZoomImage from '../ModalZoomImage';

const DeskCarousel = ({ prevImg, carousel, fotos, listEl, idxImg, nextImg, setIdxImg, altImg }) => {
    const [isZoomOpen, setIsZoomOpen] = useState(false);
    return (
        <div className='desk-carousel'>
            <div className='desk-carousel__big-picture' onClick={() => setIsZoomOpen(true)} >
                <Image foto={fotos[idxImg]} className='desk-carousel__big-picture--img' altImg={altImg}/>
            </div>
            <div className='desk-carousel__slider'>
                <span className={idxImg === 0 ? 'desk-carousel__slider--btn-disactive' : ''}>
                    <GrPrevious onClick={prevImg} />
                </span>
                <ul className="desk-carousel__slider--container-imgs" ref={carousel}>
                    {fotos?.length > 0
                        ? fotos?.map((foto, idx) => (
                            <li
                                ref={listEl}
                                className="desk-carousel__slider--container-imgs--li"
                                key={idx}
                                onClick={() => setIdxImg(idx)}
                            >
                                <Image foto={foto} className='desk-carousel__slider--container-imgs--li--img' altImg={altImg + ' ' + idx} />
                            </li>
                        ))
                        : <li className="desk-carousel__slider--container-imgs--li">
                            <img
                                src={imgDefault}
                                className="desk-carousel__slider--container-imgs--li--img"
                                alt='img'
                            />
                        </li>}
                </ul>
                <span className={idxImg === fotos?.length - 1 ? 'desk-carousel__slider--btn-disactive' : ''}>
                    <GrNext onClick={nextImg}/>
                </span>
            </div>
            {isZoomOpen && <ModalZoomImage setIsZoomOpen={setIsZoomOpen} fotos={fotos} startIndex={idxImg} />}
        </div>);
};

DeskCarousel.propTypes = {
    fotos: PropTypes.array,
    prevImg: PropTypes.func,
    carousel: PropTypes.any,
    setIdxImg: PropTypes.func,
    listEl: PropTypes.any,
    idxImg: PropTypes.number,
    nextImg: PropTypes.func,
    altImg: PropTypes.string
};

export default DeskCarousel;
