import { useEffect, useState } from 'react';
import config from '../config';

const useGoogleApi = () => {
    const [isApiLoaded, setIsApiLoaded] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');

        const apiKey = config.googlePlaces.apiKey;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;

        script.onload = () => {
            setIsApiLoaded(true);
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return isApiLoaded;
};

export default useGoogleApi;
