import { useEffect, useMemo, useState } from 'react';
import { HiOutlineMenu } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { FaUserAlt } from 'react-icons/fa';
import { MdShoppingCart } from 'react-icons/md';
// import logo2 from '../../../images/login/logo2.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import './navbar.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilters } from '../../../redux/actions/filters';
import LanguageBtn from './LanguageBtn';

const Navbar = ({ setIsOpenAuthModal }) => {
    const { t } = useTranslation();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [pathname, setPathname] = useState('/');
    const cartLength = useSelector((state) => state.cart?.piezas?.length || 0);
    const user = useSelector((state) => state.auth);
    const filters = useSelector((state) => state.filters);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const MenuIcon = useMemo(() => HiOutlineMenu, []);
    const CloseIcon = useMemo(() => IoMdClose, []);
    const UserIcon = useMemo(() => FaUserAlt, []);
    const CartIcon = useMemo(() => MdShoppingCart, []);

    const handleChangePath = useMemo(() => (newPathName) => {
        setPathname(newPathName);
        setIsNavOpen(false);
    }, []);

    const handleResetFilters = useMemo(() => (pathname) => {
        if (['/carrito', '/contacto', '/compra'].includes(pathname)) return true;
        if (pathname.includes('/area-personal')) return true;
        return false;
    }, []);

    useEffect(() => {
        handleChangePath(location.pathname);
        if (filters && handleResetFilters(location.pathname)) {
            dispatch(resetFilters());
        }
    }, [location.pathname]);

    return (
        <nav className="navbar">
            <Link to="/" className="navbar__logo">
                <img src={window.location.origin + '/recomotor-logo.png'} alt="Recomotor" />
            </Link>
            <button
                className="navbar__hamburger"
                onClick={() => setIsNavOpen(prev => !prev)}
            >
                {isNavOpen ? <CloseIcon /> : <MenuIcon />}
            </button>
            <div
                className={
                    isNavOpen
                        ? 'navbar__menu navbar__menu--expanded'
                        : 'navbar__menu'
                }
            >
                <ul>
                    <li className={pathname === '/' ? 'active' : ''}>
                        <Link to="/">{t('navbar.piezas')}</Link>
                    </li>
                    <li className={pathname === '/compra' ? 'active' : ''}>
                        <Link to="/compra">{t('navbar.compra')}</Link>
                    </li>
                    <li className={pathname === '/contacto' ? 'active' : ''}>
                        <Link to="/contacto">{t('navbar.contacto')}</Link>
                    </li>
                    <li>
                        <LanguageBtn />
                    </li>
                </ul>
                <div
                    className="navbar__menu--cart navbar__menu--cart--expanded"
                    onClick={() => navigate('/carrito')}
                >
                    <span className="navbar__menu--cart--icon">
                        <CartIcon />
                    </span>
                    <div className="navbar__menu--cart--number">
                        {cartLength}
                    </div>
                </div>
                <div
                    className={`navbar__menu--user ${!user && 'no-user'}`}
                    onClick={() =>
                        !user
                            ? setIsOpenAuthModal('login')
                            : navigate('/area-personal')
                    }
                >
                    <span className="navbar__menu--user--icon">
                        <UserIcon />
                    </span>
                    <div className="navbar__menu--user--desk">
                        {user
                            ? <>
                                <p className="navbar__menu--user--desk--name">
                                    {user?.nombre}
                                </p>
                                <p className="navbar__menu--user--desk--area">
                                    {t('navbar.area')} &gt;
                                </p>
                            </>
                            : <p className="navbar__menu--user--desk--login">
                                {t('navbar.login')}
                            </p>
                        }
                    </div>
                </div>
            </div>
        </nav>
    );
};

Navbar.propTypes = {
    setIsOpenAuthModal: PropTypes.func
};

export default Navbar;
